import { emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import ReactHtmlParser from 'react-html-parser';
import { handleEnterOrSpaceKeyPress } from '../../../Accessibility/KeyboardEvents';
import { Link } from '../../../Resource/Types';
import { NotTranslated } from '../../../Translations';
import DocumentIcon from '../../Cutesies/DocumentIcon';
import DownArrowIcon from '../../Cutesies/DownArrowIcon';
import DownloadViewModal from '../../DownloadViewModal';
import { useOpenable } from '../../Openable';
import ImportantIcon from '../ImportantIcon';
import { Document } from '../Types';
import * as style from './style.module.css';

interface Props {
  document: Document;
  links: ReadonlyArray<Link>;
}

function TopLevelDocumentResource({ document, links }: Props) {
  const { openableState, open, close } = useOpenable();

  return (
    <div className={style.resource}>
      <a
        className={style.content}
        onClick={open}
        data-test-top-leveldocument-resource={true}
        tabIndex={0}
        onKeyPress={handleEnterOrSpaceKeyPress(open)}
      >
        <div className={style.icon}>
          <DocumentIcon />
        </div>
        <div className={style.details}>
          <span className={style.name} data-test-document-resource-title={true}>
            <ImportantIcon important={document.important} className={style.importantIcon} />
            <NotTranslated text={document.title} />
          </span>
          {document.description
            .map((description) => (
              <span className={style.description}>{ReactHtmlParser(description)}</span>
            ))
            .getOrElse(emptyFragment)}
        </div>
        <div className={style.rightSideWrapper}>
          <span className={style.fileExtension}>
            {document.assetFileExtension
              .map((ext) => <NotTranslated text={ext} />)
              .getOrElse(emptyFragment)}
          </span>
          <div className={style.download}>
            <div className={style.downloadIcon}>
              <DownArrowIcon />
            </div>
          </div>
        </div>
      </a>
      <DownloadViewModal
        resource={{ payload: document, links }}
        modalState={openableState}
        close={close}
      />
    </div>
  );
}

export default observer(TopLevelDocumentResource);
