import { explicitMaybe, mergeObjectDecoders, stringLiteral } from '@execonline-inc/decoders';
import { alreadyTranslatedText } from '@execonline-inc/translations';
import { identity } from '@kofno/piper';
import Decoder, { array, field, number, oneOf, succeed } from 'jsonous';
import { resourceDecoder } from '../../Resource/Decoders';
import { baseResourceDecoder, documentPartsDecoder } from '../../ResourceStore/Decoders';
import {
  DocumentResource,
  Linkable,
  LinkableDiscoveryPortalWizardKind,
  LinkableDiscoveryPortalWizardKindScope,
  LinkableRescourceKind,
  LinkableResource,
} from './Types';

const documentPartsResourceDecoder: Decoder<DocumentResource> = resourceDecoder(
  mergeObjectDecoders(baseResourceDecoder, documentPartsDecoder),
);

export const linkableDiscoveryPortalWizardScopeDecoder: Decoder<LinkableDiscoveryPortalWizardKindScope> =
  succeed({})
    .assign('kind', field('kind', stringLiteral('product-collection')))
    .assign('id', field('id', number));

export const linkableResourceKindDecoder: Decoder<LinkableRescourceKind> = succeed({})
  .assign('label', field('label', alreadyTranslatedText))
  .assign('kind', field('kind', stringLiteral('resource')))
  .assign('resource', field('resource', explicitMaybe(documentPartsResourceDecoder)));

export const linkableDiscoveryPortalWizardDecoder: Decoder<LinkableDiscoveryPortalWizardKind> =
  succeed({})
    .assign('label', field('label', alreadyTranslatedText))
    .assign('kind', field('kind', stringLiteral('discovery-portal-wizard')))
    .assign('scope', field('scope', array(linkableDiscoveryPortalWizardScopeDecoder)));

export const linkableDecoder: Decoder<Linkable> = oneOf<Linkable>([
  linkableDiscoveryPortalWizardDecoder.map<Linkable>(identity),
  linkableResourceKindDecoder.map<Linkable>(identity),
]);

export const linkableResourceDecoder: Decoder<LinkableResource> = resourceDecoder(linkableDecoder);
