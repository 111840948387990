import { Maybe, fromNullable } from 'maybeasy';
import { observer } from 'mobx-react';
import { ExperienceResources, ProductCollection } from '../../../../../../Common/Experience/Types';
import EnrollmentStore from '../../../../../Enrollment/Store';
import CompetencyFilterStore from '../../../../Filters/CompetencyFilter/Store';
import DurationFilterStore from '../../../../Filters/DurationFilter/Store';
import FilterStore from '../../../../Filters/FilterStore';
import LanguageFilterStore from '../../../../Filters/LanguageFilter/Store';
import SchoolPartnerFilterStore from '../../../../Filters/SchoolPartnerFilter/Store';
import ListViewExperiences from '../../ListViewExperiences';
import TileViewCarouselExperiences from '../../TileViewCarouselExperiences';
import TileViewExperiences from '../../TileViewExperiences';

interface Props {
  invitationUuid: string;
  enrollmentStore: EnrollmentStore;
  productCollection: ProductCollection;
  experienceResources: ExperienceResources;
  filterStore: FilterStore;
  languageFilterStore: LanguageFilterStore;
  durationFilterStore: DurationFilterStore;
  schoolPartnerFilterStore: SchoolPartnerFilterStore;
  competencyFilterStore: CompetencyFilterStore;
  searchValue: Maybe<string>;
}

export const ProductCollectionDisplayType: React.FC<Props> = ({
  invitationUuid,
  enrollmentStore,
  experienceResources,
  filterStore,
  languageFilterStore,
  durationFilterStore,
  schoolPartnerFilterStore,
  productCollection,
  competencyFilterStore,
  searchValue,
}) => {
  switch (productCollection.displayType) {
    case 'grid':
      return (
        <TileViewExperiences
          kind="product-collection"
          invitationUuid={invitationUuid}
          experienceResources={experienceResources}
          enrollmentStore={enrollmentStore}
          productCollection={fromNullable(productCollection)}
          filterStore={filterStore}
          languageFilterStore={languageFilterStore}
          durationFilterStore={durationFilterStore}
          schoolPartnerFilterStore={schoolPartnerFilterStore}
          competencyFilterStore={competencyFilterStore}
          searchValue={searchValue}
        />
      );
    case 'carousel':
      return (
        <TileViewCarouselExperiences
          filterStore={filterStore}
          productCollection={productCollection}
          languageFilterStore={languageFilterStore}
          durationFilterStore={durationFilterStore}
          schoolPartnerFilterStore={schoolPartnerFilterStore}
          invitationUuid={invitationUuid}
          enrollmentStore={enrollmentStore}
          competencyFilterStore={competencyFilterStore}
          searchValue={searchValue}
        />
      );
    case 'list':
      return (
        <ListViewExperiences
          kind="product-collection"
          productCollection={fromNullable(productCollection)}
          invitationUuid={invitationUuid}
          experienceResources={experienceResources}
          enrollmentStore={enrollmentStore}
          filterStore={filterStore}
          languageFilterStore={languageFilterStore}
          durationFilterStore={durationFilterStore}
          schoolPartnerFilterStore={schoolPartnerFilterStore}
          competencyFilterStore={competencyFilterStore}
          searchValue={searchValue}
        />
      );
  }
};

export default observer(ProductCollectionDisplayType);
