import { readVarM } from '@execonline-inc/environment';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import { toUrl } from '@execonline-inc/url';
import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { resetPasswordRoute } from '../../../../../ClientRoutes';
import { Button, Typography } from '../../../../../components/UI';
import { T } from '../../../../../Translations';
import { RegisterResource } from '../../../DiscoveryPortal/RegisterResourceStore/Types';
import ExternalLink from '../../../ExternalLink';
import TermsAndConditionsFormField from '../../../TermsAndConditionsFormField';
import UsePasswordStore from '../../UsePasswordStore';
import FormFields from './FormFields';

interface Props {
  registerResource: RegisterResource;
  usePasswordStore: UsePasswordStore;
}

const PasswordForm: React.FC<Props> = ({ usePasswordStore, registerResource }) => {
  switch (registerResource.payload.auth.kind) {
    case 'password-auth':
      return (
        <div className="flex w-[600px] max-w-full flex-col gap-4 md:gap-6">
          <FormFields usePasswordStore={usePasswordStore} registerResource={registerResource} />
          {registerResource.payload.auth.termsAccepted || (
            <TermsAndConditionsFormField
              value={usePasswordStore.termsAcceptance}
              onValueChange={usePasswordStore.setTermsAcceptance}
              disabled={false}
            />
          )}
          <div className="mt-6">
            <Button size="xl" onClick={usePasswordStore.creating}>
              <T kind="Continue" />
            </Button>
          </div>
          <div>
            {just({})
              .assign('base', readVarM('REACT_APP_BASE_NAME'))
              .assign('origin', readVarM('REACT_APP_API_ROOT'))
              .andThen(({ base, origin }) => toUrl(base + resetPasswordRoute, origin))
              .map((url) => (
                <ExternalLink href={url.href}>
                  <Typography className="underline" variant="body2" component="span">
                    <T kind="I forgot my password" />
                  </Typography>
                </ExternalLink>
              ))
              .getOrElse(emptyFragment)}
          </div>
        </div>
      );
    case 'locked-access-auth':
    case 'sso-auth':
      return <></>;
  }
};

export default observer(PasswordForm);
