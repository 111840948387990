import { equals, find } from '@execonline-inc/collections';
import { SelectFieldValue, StringFormField } from '../../../Types';

export const stringInputOptionLabel = (stringFormField: StringFormField) =>
  stringFormField.label.getOrElseValue(stringFormField.name);

export const convertToSelectFieldValue = (label: string, value: string): SelectFieldValue => ({
  label,
  value,
});

export const inputValue = (stringFormField: StringFormField, fieldValue: SelectFieldValue[]) =>
  find((v) => equals(v.label, stringInputOptionLabel(stringFormField)), fieldValue)
    .map((v) => v.value)
    .getOrElseValue('');
