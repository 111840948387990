import { emptyFragment } from '@execonline-inc/execonline-ui';
import { when } from '@execonline-inc/maybe-adapter';
import { Maybe, nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { AlreadyTranslated } from '../../../../../../../Translations';
import {
  ExperienceDisplayTypeKind,
  ExperienceResources,
  ProductCollection,
} from '../../../../../Common/Experience/Types';
import EnrollmentStore from '../../../../Enrollment/Store';
import CompetencyFilterStore from '../../../Filters/CompetencyFilter/Store';
import DurationFilterStore from '../../../Filters/DurationFilter/Store';
import FilterStore from '../../../Filters/FilterStore';
import LanguageFilterStore from '../../../Filters/LanguageFilter/Store';
import SchoolPartnerFilterStore from '../../../Filters/SchoolPartnerFilter/Store';
import ExperienceSection from '../Common/ExperienceSection';
import ExperienceTitleWithSearch from '../Common/ExperienceTitleWithSearch';
import ProductCollectionDisplay from '../ProductCollections/ProductCollectionDisplay';
import ShowExperiences from './ShowExperiences';

interface Props {
  invitationUuid: string;
  experienceResources: ExperienceResources;
  enrollmentStore: EnrollmentStore;
  filterStore: FilterStore;
  languageFilterStore: LanguageFilterStore;
  durationFilterStore: DurationFilterStore;
  schoolPartnerFilterStore: SchoolPartnerFilterStore;
  kind: ExperienceDisplayTypeKind;
  productCollection: Maybe<ProductCollection>;
  competencyFilterStore: CompetencyFilterStore;
  searchValue: Maybe<string>;
}

const ListViewExperiences: React.FC<Props> = ({
  invitationUuid,
  experienceResources,
  enrollmentStore,
  filterStore,
  languageFilterStore,
  durationFilterStore,
  schoolPartnerFilterStore,
  kind,
  productCollection,
  competencyFilterStore,
  searchValue,
}) => {
  const showAll = (experienceResources: ExperienceResources): boolean =>
    experienceResources.some((exp) =>
      filterStore.showFilteredExperience(
        exp,
        languageFilterStore,
        durationFilterStore,
        schoolPartnerFilterStore,
        competencyFilterStore,
      ),
    );
  switch (kind) {
    case 'browse-all':
    case 'experiences-only':
      return when(showAll, experienceResources)
        .map((experienceResources) => (
          <ExperienceSection
            className="bg-inherit pb-6 pt-0 !shadow-none sm:py-8"
            title={
              <ExperienceTitleWithSearch
                headerTitle="All Programs in this Catalog"
                searchValue={searchValue}
              />
            }
            description={nothing()}
          >
            <ShowExperiences
              experiences={experienceResources}
              invitationUuid={invitationUuid}
              enrollmentStore={enrollmentStore}
              filterStore={filterStore}
              languageFilterStore={languageFilterStore}
              durationFilterStore={durationFilterStore}
              schoolPartnerFilterStore={schoolPartnerFilterStore}
              competencyFilterStore={competencyFilterStore}
            />
          </ExperienceSection>
        ))
        .getOrElse(emptyFragment);
    case 'product-collection':
      return productCollection
        .map((pc) => (
          <ExperienceSection
            title={<AlreadyTranslated content={pc.name} />}
            description={pc.description}
          >
            <ProductCollectionDisplay productCollection={pc}>
              <ShowExperiences
                experiences={pc.experiences}
                invitationUuid={invitationUuid}
                enrollmentStore={enrollmentStore}
                filterStore={filterStore}
                languageFilterStore={languageFilterStore}
                durationFilterStore={durationFilterStore}
                schoolPartnerFilterStore={schoolPartnerFilterStore}
                competencyFilterStore={competencyFilterStore}
              />
            </ProductCollectionDisplay>
          </ExperienceSection>
        ))
        .getOrElse(emptyFragment);
  }
};

export default observer(ListViewExperiences);
