import { emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import ExperiencesStore from '../Experiences/ExperiencesStore';
import { CompetencyFilterStore } from './CompetencyFilter';
import { DurationFilterStore } from './DurationFilter';
import InlineFilters from './InlineFilters';
import { LanguageFilterStore } from './LanguageFilter';
import { SchoolPartnerFilterStore } from './SchoolPartnerFilter';
import SidebarFilters from './SidebarFilters';
import { Link } from '../../../../../Resource/Types';
import { Maybe } from 'maybeasy';

export interface Props {
  experiencesStore: ExperiencesStore;
  languageFilterStore: LanguageFilterStore;
  durationFilterStore: DurationFilterStore;
  schoolPartnerFilterStore: SchoolPartnerFilterStore;
  competencyFilterStore: CompetencyFilterStore;
  link: Maybe<Link>;
}

function Filter({
  competencyFilterStore,
  durationFilterStore,
  experiencesStore,
  languageFilterStore,
  schoolPartnerFilterStore,
  link,
}: Props) {
  return experiencesStore.filterOptionsResource
    .map((options) => (
      <div className="mx-auto box-border flex gap-x-2 px-6 pb-8 pt-0 xl:container md:flex-wrap md:gap-x-0 md:px-16">
        <InlineFilters
          experiencesStore={experiencesStore}
          languageFilterStore={languageFilterStore}
          durationFilterStore={durationFilterStore}
          schoolPartnerFilterStore={schoolPartnerFilterStore}
          competencyFilterStore={competencyFilterStore}
          options={options}
          link={link}
        />
        <SidebarFilters
          languageFilterStore={languageFilterStore}
          durationFilterStore={durationFilterStore}
          schoolPartnerFilterStore={schoolPartnerFilterStore}
          competencyFilterStore={competencyFilterStore}
          options={options}
        />
      </div>
    ))
    .getOrElse(emptyFragment);
}

export default observer(Filter);
