import { emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import Loading from '../../../../components/Loading';
import EnrollmentStore from '../Enrollment/Store';
import { ExperiencesContext } from '../ExperienceSelection/Experiences/ExperiencesContext';
import ExperiencesStore from '../ExperienceSelection/Experiences/ExperiencesStore';
import PromotablesView from './PromotablesView';

interface Props {
  experiencesStore: ExperiencesStore;
  invitationUuid: string;
  enrollmentStore: EnrollmentStore;
}

function PromotablesSection({ experiencesStore, enrollmentStore, invitationUuid }: Props) {
  switch (experiencesStore.state.kind) {
    case 'waiting':
    case 'loading':
      return <Loading />;
    case 'error':
    case 'searching':
      return emptyFragment();
    case 'experiences':
    case 'product-collections':
    case 'product-collections-and-experiences':
      return (
        <ExperiencesContext.Provider value={experiencesStore}>
          <PromotablesView
            links={experiencesStore.state.resource.links}
            enrollmentStore={enrollmentStore}
            invitationUuid={invitationUuid}
          />
        </ExperiencesContext.Provider>
      );
  }
}

export default observer(PromotablesSection);
