import { find } from '@execonline-inc/collections';
import { toResult } from '@execonline-inc/maybe-adapter';
import {
  Link as LinkR,
  Linkable as LinkableR,
  PossiblyLinkable as PossiblyLinkableR,
  Resource as ResourceR,
  ResourceWithErrors as ResourceWithErrorsR,
  ResourceWithMetadata as ResourceWithMetadataR,
  payload as payloadR,
  resource as resourceR,
} from '@execonline-inc/resource';
import { Result } from 'resulty';

const rels = [
  'achievements',
  'adaptive',
  'add',
  'add-complete',
  'add-current',
  'add-progress-detail',
  'announcements',
  'app-session',
  'assertion-backend',
  'assignments',
  'attachment',
  'attributes',
  'auth',
  'avatar',
  'broadcast-message',
  'broadcast-poll',
  'broadcast-poll-close',
  'calendar',
  'calendaring-app',
  'cancel',
  'children',
  'client-portal-app',
  'coach',
  'coach-communities',
  'coach-team-options',
  'coach-teams-summary',
  'collection',
  'conference-room',
  'country-options',
  'country-region-options',
  'communities',
  'compressed-enclosure',
  'course-document-template',
  'create',
  'create-engagement',
  'current-events',
  'current-user',
  'dashboard',
  'delete',
  'direct-enrollment-discovery-portal',
  'discovery-portal',
  'discussions',
  'download',
  'download-digital-cert-preview',
  'download-overview-pdf',
  'edit',
  'edit-profile',
  'enclosure',
  'enroll',
  'enterprise-portal-app',
  'error',
  'events',
  'experience-template-link',
  'external-conference-url',
  'filestack-resize-url',
  'first',
  'freebusy',
  'freebusy-coaching',
  'freebusy-program',
  'history',
  'icon',
  'join-event',
  'in-reply-to',
  'last',
  'last-read-message',
  'launch-poll',
  'launch-poll-close',
  'learning-partners',
  'learning-partner-resources',
  'live-meeting-recording-resources',
  'linkables',
  'login',
  'logo',
  'logo-on-dark',
  'logo-on-white',
  'logout',
  'logs',
  'messages',
  'meta',
  'my-hand-broadcast',
  'my-hand-down',
  'my-hand-up',
  'my-personalized-resources',
  'my-uploaded-resources',
  'needed-for-assignment-resources',
  'next',
  'notes',
  'notes-create',
  'notifications',
  'orientation-commencement',
  'original',
  'organization',
  'other-hand-down',
  'overview-video',
  'page-template',
  'parent',
  'preferred-language',
  'present-participants',
  'prev',
  'preview',
  'previous',
  'print',
  'privacy',
  'professors',
  'profile',
  'program',
  'program-template',
  'programs',
  'progress',
  'promotables',
  'remove',
  'remove-complete',
  'resume',
  'request-coach',
  'reschedule',
  'reset-password',
  'resized-avatar',
  'resources-dashboard',
  'replies',
  'resource-template',
  'resources',
  'results-create',
  'roster',
  'segment-preview-template',
  'shared-invitation-template',
  'staff-join',
  'staff-left',
  'self',
  'simple-file-upload',
  'sso',
  'success',
  'supported-languages',
  'sprite',
  'subscribe',
  'summary',
  'support',
  'survey',
  'targeted-invitation-template',
  'team',
  'temporary-avatar',
  'team-details',
  'team-template',
  'teams',
  'teams-summary',
  'terms',
  'terms_and_conditions',
  'thumb',
  'thumbnail',
  'timer',
  'token-renewal',
  'toolings',
  'unified-login',
  'unsubscribe',
  'unsubscribe-invitation-template',
  'unsubscribe-progress-email-template',
  'update',
  'update-password',
  'user',
  'video',
  'view',
  'viewable-attachment-template',
  'validate-token',
  'last-read-message',
  'commerce',
  'pending-surveys',
  'auto-launchables',
  'participant-postponables',
  'change-coach',
  'search-discovery-portal',
  'learning-collection-logo',
] as const;

export type Rel = (typeof rels)[number];

export const toRel = (value: string): Result<string, Rel> =>
  toResult(
    `Expected to find an HTTP rel string. Instead I found ${value}`,
    find((rel) => rel === value, rels),
  );

export type Link = LinkR<Rel>;
export type Linkable = LinkableR<Rel>;
export type PossiblyLinkable = PossiblyLinkableR<Rel>;

export type Resource<T> = ResourceR<T, Rel>;
export type ResourceWithErrors<T> = ResourceWithErrorsR<T, Rel>;

export type ResourceWithMetadata<T, M> = ResourceWithMetadataR<T, M, Rel>;

export interface ResourceWithEmbeddedR<T, E, Rel extends string> extends ResourceR<T, Rel> {
  embedded: E;
}

export type ResourceWithEmbedded<T, E> = ResourceWithEmbeddedR<T, E, Rel>;

export const resourceWithEmbeddedR: <Rel extends string, T, E>(
  links: readonly LinkR<Rel>[],
  payload: T,
  embedded: E,
) => ResourceWithEmbeddedR<T, E, Rel> = (links, payload, embedded) => ({
  links,
  payload,
  embedded,
});

export const resourceWithEmbedded: <T, E>(
  links: ReadonlyArray<Link>,
  payload: T,
  embedded: E,
) => ResourceWithEmbedded<T, E> = resourceWithEmbeddedR;

export const resource: <T>(links: ReadonlyArray<Link>, payload: T) => Resource<T> = resourceR;

export const payload: <A, R extends Resource<A>>(r: R) => A = payloadR;

export type { IdentifiablePayload, ServerError } from '@execonline-inc/resource';
