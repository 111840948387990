import { emptyFragment } from '@execonline-inc/execonline-ui';
import { Maybe, nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import { NonEmptyList } from 'nonempty-list';
import React from 'react';
import {
  ExperienceResources,
  ProductCollection,
  UseCaseDisplayType,
} from '../../../../Common/Experience/Types';
import EnrollmentStore from '../../../Enrollment/Store';
import CompetencyFilterStore from '../../Filters/CompetencyFilter/Store';
import DurationFilterStore from '../../Filters/DurationFilter/Store';
import FilterStore from '../../Filters/FilterStore';
import LanguageFilterStore from '../../Filters/LanguageFilter/Store';
import SchoolPartnerFilterStore from '../../Filters/SchoolPartnerFilter/Store';
import ExperienceDisplayType from './ExperienceDisplayType';
import ProductCollectionsSection from './ProductCollections/ProductCollectionsSection';

interface Props {
  invitationUuid: string;
  enrollmentStore: EnrollmentStore;
  productCollections: Maybe<NonEmptyList<ProductCollection>>;
  experiences: ExperienceResources;
  filterStore: FilterStore;
  languageFilterStore: LanguageFilterStore;
  durationFilterStore: DurationFilterStore;
  schoolPartnerFilterStore: SchoolPartnerFilterStore;
  competencyFilterStore: CompetencyFilterStore;
  displayType: UseCaseDisplayType;
  searchValue: Maybe<string>;
}

const BothProductCollectionsAndExperiences: React.FC<Props> = ({
  enrollmentStore,
  invitationUuid,
  productCollections,
  experiences,
  filterStore,
  languageFilterStore,
  durationFilterStore,
  schoolPartnerFilterStore,
  competencyFilterStore,
  displayType,
  searchValue,
}) => {
  const displayTypeWithSearch = searchValue
    .map<UseCaseDisplayType>(() => 'list')
    .getOrElseValue(displayType);

  return (
    <>
      {productCollections
        .map((productCollectionsList) => (
          <ProductCollectionsSection
            filterStore={filterStore}
            productCollectionsList={productCollectionsList}
            languageFilterStore={languageFilterStore}
            durationFilterStore={durationFilterStore}
            schoolPartnerFilterStore={schoolPartnerFilterStore}
            competencyFilterStore={competencyFilterStore}
            invitationUuid={invitationUuid}
            enrollmentStore={enrollmentStore}
            searchValue={searchValue}
          />
        ))
        .getOrElse(emptyFragment)}

      <ExperienceDisplayType
        kind="browse-all"
        filterStore={filterStore}
        languageFilterStore={languageFilterStore}
        durationFilterStore={durationFilterStore}
        schoolPartnerFilterStore={schoolPartnerFilterStore}
        competencyFilterStore={competencyFilterStore}
        invitationUuid={invitationUuid}
        experienceResources={experiences}
        enrollmentStore={enrollmentStore}
        searchValue={searchValue}
        displayType={displayTypeWithSearch}
        productCollection={nothing()}
      />
    </>
  );
};

export default observer(BothProductCollectionsAndExperiences);
