import { emptyFragment } from '@execonline-inc/execonline-ui';
import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import { Typography } from '../../components/UI';
import { findLinkBy } from '../../Links';
import { rootResourceStore } from '../../RootResourceStore';
import { T } from '../../Translations';
import CheckBox from '../Form/CheckBox';
import ExternalLink from './ExternalLink';

interface Props {
  value: boolean;
  onValueChange: (value: boolean) => void;
  disabled: boolean;
}

function TermsAndConditionsFormField(props: Props) {
  return just({})
    .assign('privacyLink', findLinkBy({ rel: 'privacy' }, rootResourceStore.links))
    .assign('termsLink', findLinkBy({ rel: 'terms_and_conditions' }, rootResourceStore.links))
    .map(({ privacyLink, termsLink }) => (
      <div className="flex items-center gap-x-2.5">
        <CheckBox {...props} />
        <Typography className="uppercase tracking-wider" fontWeight="medium">
          <T
            kind="I accept the <link1>terms of use</link1> and <link2>privacy policy</link2>"
            link1={(content) => (
              <ExternalLink href={termsLink.href} data-test-terms-of-use-link={true}>
                <Typography className="uppercase tracking-wider underline" component="span">
                  {content}
                </Typography>
              </ExternalLink>
            )}
            link2={(content) => (
              <ExternalLink href={privacyLink.href} data-test-privacy-policy-link={true}>
                <Typography className="uppercase tracking-wider underline" component="span">
                  {content}
                </Typography>
              </ExternalLink>
            )}
          />
        </Typography>
      </div>
    ))
    .getOrElse(emptyFragment);
}

export default observer(TermsAndConditionsFormField);
