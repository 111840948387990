import { emptyFragment } from '@execonline-inc/execonline-ui';
import { when } from '@execonline-inc/maybe-adapter';
import { Maybe, nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import { AlreadyTranslated } from '../../../../../../../Translations';
import {
  ExperienceDisplayTypeKind,
  ExperienceResources,
  ProductCollection,
} from '../../../../../Common/Experience/Types';
import DiscoveryContext from '../../../../../DiscoveryContext';
import EnrollmentStore from '../../../../Enrollment/Store';
import CompetencyFilterStore from '../../../Filters/CompetencyFilter/Store';
import DurationFilterStore from '../../../Filters/DurationFilter/Store';
import FilterStore from '../../../Filters/FilterStore';
import LanguageFilterStore from '../../../Filters/LanguageFilter/Store';
import SchoolPartnerFilterStore from '../../../Filters/SchoolPartnerFilter/Store';
import ExperienceSection from '../Common/ExperienceSection';
import ExperienceTitleWithSearch from '../Common/ExperienceTitleWithSearch';
import ProductCollectionDisplay from '../ProductCollections/ProductCollectionDisplay';
import ShowExperiences from './ShowExperiences';

interface Props {
  invitationUuid: string;
  experienceResources: ExperienceResources;
  enrollmentStore: EnrollmentStore;
  filterStore: FilterStore;
  languageFilterStore: LanguageFilterStore;
  durationFilterStore: DurationFilterStore;
  schoolPartnerFilterStore: SchoolPartnerFilterStore;
  competencyFilterStore: CompetencyFilterStore;
  searchValue: Maybe<string>;
  kind: ExperienceDisplayTypeKind;
  productCollection: Maybe<ProductCollection>;
}

const TileViewExperiences: React.FC<Props> = ({
  invitationUuid,
  experienceResources,
  enrollmentStore,
  filterStore,
  languageFilterStore,
  durationFilterStore,
  schoolPartnerFilterStore,
  competencyFilterStore,
  searchValue,
  kind,
  productCollection,
}) => {
  const showAll = (experienceResources: ExperienceResources): boolean =>
    experienceResources.some((exp) =>
      filterStore.showFilteredExperience(
        exp,
        languageFilterStore,
        durationFilterStore,
        schoolPartnerFilterStore,
        competencyFilterStore,
      ),
    );

  switch (kind) {
    case 'browse-all':
    case 'experiences-only':
      return (
        <DiscoveryContext.Provider value={{ kind: 'all-experiences' }}>
          {when(showAll, experienceResources)
            .map((experienceResources) => (
              <ExperienceSection
                className="bg-inherit pb-6 pt-0 !shadow-none sm:py-8"
                title={
                  <ExperienceTitleWithSearch
                    headerTitle="All Programs in this Catalog"
                    searchValue={searchValue}
                  />
                }
                description={nothing()}
              >
                <ShowExperiences
                  invitationUuid={invitationUuid}
                  experiences={experienceResources}
                  enrollmentStore={enrollmentStore}
                  filterStore={filterStore}
                  languageFilterStore={languageFilterStore}
                  durationFilterStore={durationFilterStore}
                  schoolPartnerFilterStore={schoolPartnerFilterStore}
                  competencyFilterStore={competencyFilterStore}
                />
              </ExperienceSection>
            ))
            .getOrElse(emptyFragment)}
        </DiscoveryContext.Provider>
      );
    case 'product-collection':
      return (
        <DiscoveryContext.Provider value={{ kind: 'all-experiences' }}>
          {productCollection
            .map((pc) => (
              <ExperienceSection
                className="bg-inherit pb-6 pt-0 !shadow-none sm:py-8"
                title={<AlreadyTranslated content={pc.name} />}
                description={pc.description}
              >
                <ProductCollectionDisplay productCollection={pc}>
                  <ShowExperiences
                    invitationUuid={invitationUuid}
                    experiences={pc.experiences}
                    enrollmentStore={enrollmentStore}
                    filterStore={filterStore}
                    languageFilterStore={languageFilterStore}
                    durationFilterStore={durationFilterStore}
                    schoolPartnerFilterStore={schoolPartnerFilterStore}
                    competencyFilterStore={competencyFilterStore}
                  />
                </ProductCollectionDisplay>
              </ExperienceSection>
            ))
            .getOrElse(emptyFragment)}
        </DiscoveryContext.Provider>
      );
  }
};

export default observer(TileViewExperiences);
