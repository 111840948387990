import { observer } from 'mobx-react';
import ExperiencesStore from '../Experiences/ExperiencesStore';
import CompetencyFilterStore from './CompetencyFilter/Store';
import DurationFilterStore from './DurationFilter/Store';
import LanguageFilterStore from './LanguageFilter/Store';
import SchoolPartnerFilterStore from './SchoolPartnerFilter/Store';
import Loading from '../../../../../components/Loading';
import Filter from './Filter';
import { Link } from '../../../../../Resource/Types';
import { Maybe } from 'maybeasy';

interface Props {
  experiencesStore: ExperiencesStore;
  languageFilterStore: LanguageFilterStore;
  durationFilterStore: DurationFilterStore;
  schoolPartnerFilterStore: SchoolPartnerFilterStore;
  competencyFilterStore: CompetencyFilterStore;
  link: Maybe<Link>;
}

function Filters({
  competencyFilterStore,
  durationFilterStore,
  experiencesStore,
  languageFilterStore,
  schoolPartnerFilterStore,
  link,
}: Props) {
  switch (experiencesStore.state.kind) {
    case 'waiting':
    case 'loading':
    case 'error':
      return <Loading />;
    case 'experiences':
    case 'product-collections':
    case 'product-collections-and-experiences':
    case 'searching':
      return (
        <Filter
          experiencesStore={experiencesStore}
          languageFilterStore={languageFilterStore}
          durationFilterStore={durationFilterStore}
          schoolPartnerFilterStore={schoolPartnerFilterStore}
          competencyFilterStore={competencyFilterStore}
          link={link}
        />
      );
  }
}

export default observer(Filters);
