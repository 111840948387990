import { observer } from 'mobx-react';
import { ReactNode } from 'react';
import { CarouselStore, CarouselWithButtons } from '../Experience/Carousel';
import ProgramExperienceItems from './ProgramExperienceItems';
import { ProgramExperienceCarouselProps } from './Types';

interface Props<T> {
  carouselStore: CarouselStore<T>;
  children: (props: ProgramExperienceCarouselProps<T>) => ReactNode;
}

function ProgramExperienceCarousel<T>({ carouselStore, children }: Props<T>) {
  return (
    <CarouselWithButtons carouselStore={carouselStore}>
      <ProgramExperienceItems carouselStore={carouselStore} children={children} />
    </CarouselWithButtons>
  );
}

export default observer(ProgramExperienceCarousel);
