import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { CarouselStore } from '../../../../../Common/Experience/Carousel';
import { ProductCollection } from '../../../../../Common/Experience/Types';
import { TileViewProgramExperience } from '../../../../../Common/ProgramExperience';
import { ProgramExperienceCarousel } from '../../../../../Common/ProgramExperienceCarousel';
import EnrollmentStore from '../../../../Enrollment/Store';
import { useExperiencesContext } from '../../ExperiencesContext';

interface Props {
  invitationUuid: string;
  enrollmentStore: EnrollmentStore;
  productCollection: ProductCollection;
}

function TileCarouselExperiences({ invitationUuid, enrollmentStore, productCollection }: Props) {
  const experiencesStore = useExperiencesContext();
  const carouselStore = useMemo(
    () => new CarouselStore(productCollection.experiences, productCollection.displayPerPageCount),
    [productCollection],
  );

  return (
    <ProgramExperienceCarousel carouselStore={carouselStore}>
      {({ className, items }) => {
        return items.map((experience) => (
          <TileViewProgramExperience
            key={experience.payload.experienceId}
            className={className}
            invitationUuid={invitationUuid}
            experienceResource={experience}
            experiencesStore={experiencesStore}
            enrollmentStore={enrollmentStore}
          />
        ));
      }}
    </ProgramExperienceCarousel>
  );
}

export default observer(TileCarouselExperiences);
