import { useMediaQuery } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { ExperienceResource } from '../../../Common/Experience/Types';
import { TileViewProgramExperience } from '../../../Common/ProgramExperience';
import EnrollmentStore from '../../Enrollment/Store';
import ExperiencesStore from '../../ExperienceSelection/Experiences/ExperiencesStore';
import PromotableExperienceTileView from './PromotableExperienceTileView';

interface Props {
  invitationUuid: string;
  experienceResource: ExperienceResource;
  experiencesStore: ExperiencesStore;
  enrollmentStore: EnrollmentStore;
}

function PromotableCarouselTileSwitcher({
  invitationUuid,
  experienceResource,
  experiencesStore,
  enrollmentStore,
}: Props) {
  const breakpoint = useMediaQuery();

  switch (breakpoint) {
    case 'xl':
      return (
        <PromotableExperienceTileView
          invitationUuid={invitationUuid}
          experienceResource={experienceResource}
          experiencesStore={experiencesStore}
          enrollmentStore={enrollmentStore}
        />
      );
    case 'lg':
    case 'md':
    case 'sm':
    case 'xs':
      return (
        <TileViewProgramExperience
          invitationUuid={invitationUuid}
          experienceResource={experienceResource}
          experiencesStore={experiencesStore}
          enrollmentStore={enrollmentStore}
        />
      );
  }
}

export default observer(PromotableCarouselTileSwitcher);
