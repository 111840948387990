import { emptyFragment } from '@execonline-inc/execonline-ui';
import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import { NonEmptyList } from 'nonempty-list';
import React from 'react';
import { ProductCollection } from '../../../../../Common/Experience/Types';
import EnrollmentStore from '../../../../Enrollment/Store';
import CompetencyFilterStore from '../../../Filters/CompetencyFilter/Store';
import DurationFilterStore from '../../../Filters/DurationFilter/Store';
import FilterStore from '../../../Filters/FilterStore';
import LanguageFilterStore from '../../../Filters/LanguageFilter/Store';
import SchoolPartnerFilterStore from '../../../Filters/SchoolPartnerFilter/Store';
import ProductCollectionsSection from './ProductCollectionsSection';

interface Props {
  invitationUuid: string;
  enrollmentStore: EnrollmentStore;
  productCollections: Maybe<NonEmptyList<ProductCollection>>;
  filterStore: FilterStore;
  languageFilterStore: LanguageFilterStore;
  durationFilterStore: DurationFilterStore;
  schoolPartnerFilterStore: SchoolPartnerFilterStore;
  competencyFilterStore: CompetencyFilterStore;
  searchValue: Maybe<string>;
}

const ProductCollections: React.FC<Props> = ({
  enrollmentStore,
  invitationUuid,
  productCollections,
  filterStore,
  languageFilterStore,
  durationFilterStore,
  schoolPartnerFilterStore,
  competencyFilterStore,
  searchValue,
}) => {
  return (
    <div className="flex flex-col gap-y-8">
      {productCollections
        .map((productCollectionsList) => (
          <ProductCollectionsSection
            filterStore={filterStore}
            languageFilterStore={languageFilterStore}
            durationFilterStore={durationFilterStore}
            schoolPartnerFilterStore={schoolPartnerFilterStore}
            productCollectionsList={productCollectionsList}
            competencyFilterStore={competencyFilterStore}
            invitationUuid={invitationUuid}
            enrollmentStore={enrollmentStore}
            searchValue={searchValue}
          />
        ))
        .getOrElse(emptyFragment)}
    </div>
  );
};

export default observer(ProductCollections);
