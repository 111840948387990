import { JsonValue } from '@execonline-inc/decoders';
import { asMaybe } from '@execonline-inc/error-handling';
import { when } from '@execonline-inc/maybe-adapter';
import { whenLt } from '@execonline-inc/numbers';
import { array } from 'jsonous';
import { just, Maybe } from 'maybeasy';
import { useEffect, useState } from 'react';
import { getFieldValue } from '../../../../../../FormFields';
import SegmentStore from '../../../../../../SegmentStore';
import { selectValueDecoder } from '../../../../Decoders';
import { FormFieldOutputValue, SelectFieldValue, SelectFormField } from '../../../../Types';
import { convertToSelectFieldValue } from '../Helpers';

export const whenMaxSelectionValid = (
  fieldValue: SelectFieldValue[],
  fieldDescription: SelectFormField,
): Maybe<number> => just(fieldValue.length).andThen(whenLt(fieldDescription.maxSelections));

const validateValue = (value: FormFieldOutputValue): Maybe<SelectFieldValue[]> =>
  asMaybe(array(selectValueDecoder).decodeAny(value));

const fieldValueM = (results: Maybe<JsonValue>, uuid: string) =>
  getFieldValue(results, uuid).andThen(validateValue).getOrElseValue([]);

export const fieldHasValue = (value: SelectFieldValue, fieldValue: SelectFieldValue[]): boolean =>
  fieldValue.some((v) => v.label === value.label);

export function useCheckboxesSelect(
  segmentStore: SegmentStore,
  fieldDescription: SelectFormField,
  uuid: string,
) {
  const [fieldValue, setFieldValue] = useState<SelectFieldValue[]>([]);
  const [isRequired, setIsRequired] = useState(false);

  useEffect(() => {
    setFieldValue(fieldValueM(segmentStore.results, uuid));
    setIsRequired(fieldDescription.mode === 'required');
  }, []);

  useEffect(() => {
    segmentStore.addEmbeddedFormFieldOutput({
      id: uuid,
      value: fieldValue,
    });

    const handler = setTimeout(() => {
      segmentStore.autoSaveFormFields();
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [fieldValue]);

  const addValue = (value: SelectFieldValue) => {
    const newFieldValue = fieldValue
      .filter((v) => v.label !== value.label)
      .concat(convertToSelectFieldValue(value.label, value.value));
    setFieldValue(newFieldValue);
  };

  const removeValue = (value: SelectFieldValue) => {
    const newFieldValue = fieldValue.filter((v) => v.label !== value.label);
    setFieldValue(newFieldValue);
  };

  const validateAndAddValue = (option: SelectFieldValue) =>
    whenMaxSelectionValid(fieldValue, fieldDescription).do(() => addValue(option));

  const onValueChange = (option: SelectFieldValue) =>
    when(fieldHasValue(option, fieldValue), true)
      .do(() => removeValue(option))
      .elseDo(() => validateAndAddValue(option));

  return {
    fieldValue,
    isRequired,
    onValueChange,
    addValue,
    removeValue,
  };
}
