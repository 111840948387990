import { cn } from '@execonline-inc/execonline-ui';
import { AlreadyTranslatedText } from '@execonline-inc/translations';
import { observer } from 'mobx-react';
import { HtmlView } from '../../../Common/Experience';

interface Props {
  data: AlreadyTranslatedText;
  className?: string;
}

const classNames = cn(
  '!text-sm sm:!text-base',
  '[&>*]:mt-2 [&>p]:m-0 [&>p+p]:mt-2',
  '[&>ul]:m-0 [&>ul>li]:marker:h-2 [&>ul>li]:marker:w-2 [&>ul>li]:marker:text-lg [&>ul>li]:marker:text-teal-600',
  '[&>ul]:text-sm [&>ul]:pl-5',
  '[&>p]:text-sm sm:[&>p]:text-base',
);

function ExperienceHtmlView({ className, data }: Props) {
  return <HtmlView description={data} className={cn(classNames, className)} />;
}

export default observer(ExperienceHtmlView);
