import {
  findItemT,
  findPayloadT,
  ItemNotFound,
  PayloadNotFound,
} from '@execonline-inc/collections';
import { always, assertNever } from '@kofno/piper';
import { BadStatus } from 'ajaxian';
import { just, Maybe, nothing } from 'maybeasy';
import { Task } from 'taskarian';
import { AppyError, callApi, postToApi } from '../../Appy';
import { programRoute } from '../../ClientRoutes';
import ErrorActionableReaction, { EAProps, handleError } from '../../ErrorActionableReaction';
import { warnAndNotify } from '../../Honeybadger';
import { findLinkT, MissingLinkError } from '../../LinkyLinky';
import { nav } from '../../Navigation';
import { programsStore } from '../../ProgramsStore';
import { ProgramResource } from '../../ProgramsStore/Types';
import { getProgram, whenActive } from '../../ProgramStore/Types';
import { Link } from '../../Resource/Types';
import SegmentStore from '../../SegmentStore';
import { segmentResourceDecoder } from '../../SegmentStore/Decoders';
import {
  SegmentResource,
  SegmentState,
  SegmentSubmissionParams,
  TeamDiscussionSubmissionParams,
} from '../../SegmentStore/Types';
import { Upload, Uploads } from '../../Uploads';
import { FormFieldOutput } from '../EmbeddedFormFieldAsset/Types';
import { dataLayerUpdate } from '../Tooling/GoogleTagManagerTooling/Loader';

type SubmissionError = PayloadNotFound | ItemNotFound | MissingLinkError | AppyError;

type AdvanceHook = (nextSegment: Maybe<SegmentResource>) => void;

interface Props extends EAProps<SegmentStore> {
  store: SegmentStore;
  params: Params;
  advanceHook: AdvanceHook;
}

interface Params {
  programId: string;
  moduleId: string;
  segmentId: string;
}

interface UploadOutput {
  upload_file_path: string;
}

interface AssignmentDueOutput {
  type: 'assignment-due';
  files: UploadOutput[];
}

interface TeamDiscussionOutput {
  type: 'team-discussion';
  files: UploadOutput[];
  team_discussion: {
    title: string;
    content: string;
  };
}

interface OverviewOutput {
  type: 'overview';
  fields: FormFieldOutput[];
}

export type SubmissionOutput = AssignmentDueOutput | TeamDiscussionOutput | OverviewOutput;

const segmentEndPoint = callApi(segmentResourceDecoder, {});

const assignmentDueOutput = (uploads: Uploads): AssignmentDueOutput => ({
  type: 'assignment-due',
  files: uploads
    .map((upload: Upload) => ({
      upload_file_path: upload.uploadFilePath,
      filename: upload.filename,
    }))
    .toArray(),
});

const teamAssignmentOutput = (
  uploads: Maybe<Uploads>,
  submissionParams: TeamDiscussionSubmissionParams,
): TeamDiscussionOutput => ({
  type: 'team-discussion',
  files: uploads.cata({
    Just: (uploads) =>
      uploads
        .map((upload: Upload) => ({
          upload_file_path: upload.uploadFilePath,
          filename: upload.filename,
        }))
        .toArray(),
    Nothing: () => [],
  }),
  team_discussion: {
    title: submissionParams.title.getOrElseValue(''),
    content: submissionParams.content.getOrElseValue(''),
  },
});

const overviewOutput = (embeddedFormFieldOutputs: FormFieldOutput[]): OverviewOutput => ({
  type: 'overview',
  fields: embeddedFormFieldOutputs.map((embeddedFormFieldOutput) => ({
    id: embeddedFormFieldOutput.id,
    value: embeddedFormFieldOutput.value,
  })),
});

const submissionsEncoder = (submissionParams: SegmentSubmissionParams): SubmissionOutput => {
  switch (submissionParams.type) {
    case 'assignment-due':
      return assignmentDueOutput(submissionParams.uploads);
    case 'team-discussion':
      return teamAssignmentOutput(submissionParams.uploads, submissionParams);
    case 'overview':
      return overviewOutput(submissionParams.embeddedFormFieldOutputs);
  }
};

const handleMissingLink = (
  store: SegmentStore,
  programId: number,
  error: MissingLinkError,
  advanceHook: AdvanceHook,
) => {
  switch (error.rel) {
    case 'update':
      store.error('This segment cannot be completed at this time.');
      break;
    case 'next':
      store.segmentResource.do((segmentResource: SegmentResource) => {
        switch (segmentResource.payload.presentationStyle) {
          case 'Full':
            nav(programRoute(programId));
            break;
          case 'Streamlined':
            advanceHook(nothing());
            break;
          default:
            assertNever(segmentResource.payload.presentationStyle);
        }
      });
      break;
    default:
      store.error('You cannot perform this operation at this time.');
  }
};

const handleBadStatus = (
  store: SegmentStore,
  programId: number,
  error: BadStatus,
  advanceHook: AdvanceHook,
) => {
  switch (error.response.status) {
    case 403:
      store.segmentResource.do((segmentResource: SegmentResource) => {
        switch (segmentResource.payload.presentationStyle) {
          case 'Full':
            store.error('You do not have access to this segment yet');
            nav(programRoute(programId));
            break;
          case 'Streamlined':
            advanceHook(nothing());
            break;
          default:
            assertNever(segmentResource.payload.presentationStyle);
        }
      });
      break;
    case 404:
      nav('/404');
      break;
    default:
      handleError(store, error);
  }
};

const handleSubmissionError =
  (store: SegmentStore, programId: number, advanceHook: AdvanceHook) =>
  (error: SubmissionError) => {
    switch (error.kind) {
      case 'missing-link-error':
        handleMissingLink(store, programId, error, advanceHook);
        break;
      case 'item-not-found':
      case 'payload-not-found':
        nav('/404');
        break;
      case 'bad-status':
        handleBadStatus(store, programId, error, advanceHook);
        break;
      default:
        handleError(store, error);
    }
  };

const advance = (store: SegmentStore, programId: number, advanceHook: AdvanceHook) => {
  Task.succeed<SubmissionError, ReadonlyArray<Link>>(store.links)
    .andThen(findLinkT('next'))
    .map<Link>((l) => ({ ...l, rel: 'self' }))
    .fork(handleSubmissionError(store, programId, advanceHook), store.loading);
};

const complete = (store: SegmentStore, programId: number, advanceHook: AdvanceHook) => {
  Task.succeed<SubmissionError, ReadonlyArray<Link>>(store.links)
    .andThen(findLinkT('update'))
    .andThen(segmentEndPoint)
    .fork(handleSubmissionError(store, programId, advanceHook), store.ready);
};

const completeAndAdvance = (store: SegmentStore, programId: number, advanceHook: AdvanceHook) => {
  Task.succeed<SubmissionError, ReadonlyArray<Link>>(store.links)
    .andThen(findLinkT('update'))
    .andThen(segmentEndPoint)
    .map((resource) => resource.links)
    .andThen(findLinkT('next'))
    .fork(handleSubmissionError(store, programId, advanceHook), store.advanceTo);
};

const submitAndAdvance = (
  store: SegmentStore,
  programId: number,
  segmentSubmissionParams: SegmentSubmissionParams,
  advanceHook: AdvanceHook,
) => {
  Task.succeed<SubmissionError, ReadonlyArray<Link>>(store.links)
    .andThen(findLinkT('update'))
    .andThen(
      callApi(segmentResourceDecoder, {
        submissions: submissionsEncoder(segmentSubmissionParams),
      }),
    )
    .do(() => segmentSubmissionParams.attachmentUploadStore.loading())
    .map((response) => response.links)
    .andThen(findLinkT('next'))
    .fork(handleSubmissionError(store, programId, advanceHook), store.advanceTo);
};

const advancingTo = (store: SegmentStore, programId: number, advanceHook: AdvanceHook) => {
  Task.succeed<SubmissionError, ReadonlyArray<Link>>(store.links)
    .andThen(findLinkT('next'))
    .andThen(segmentEndPoint)
    .fork(handleSubmissionError(store, programId, advanceHook), (segmentResource) => {
      advanceHook(just(segmentResource));
      store.ready(segmentResource);
    });
};

const reportingResultsFailure =
  (store: SegmentStore, targetOrigin: Maybe<string>) => (err: SubmissionError) => {
    store.segmentResource.map(store.ready);
    const iframe: HTMLIFrameElement = document.getElementsByTagName('iframe')[0];

    targetOrigin.do((origin) => {
      if (iframe && iframe.contentWindow) {
        iframe.contentWindow.postMessage('results-failure', origin);
      }
    });
  };

const reportingResultsSuccess = (store: SegmentStore, targetOrigin: Maybe<string>) => {
  store.segmentResource.map(store.ready);
  const iframe: HTMLIFrameElement = document.getElementsByTagName('iframe')[0];

  targetOrigin.do((origin) => {
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage('results-success', origin);
    }
  });
};

type SegmentReactionErrors = MissingLinkError | AppyError;
class Reactions extends ErrorActionableReaction<SegmentStore, SegmentState, Props> {
  programId = parseInt(this.props.params.programId, 10);
  segmentId = parseInt(this.props.params.segmentId, 10);
  moduleId = parseInt(this.props.params.moduleId, 10);

  tester = () => this.props.store.state;

  effect = (state: SegmentState) => {
    const { store, advanceHook } = this.props;

    switch (state.kind) {
      case 'loading':
        Task.succeed<SubmissionError, ReadonlyArray<Link>>(store.links)
          .andThen(findLinkT('self'))
          .andThen(segmentEndPoint)
          .fork(handleSubmissionError(store, this.programId, advanceHook), store.loaded);
        break;
      case 'completing':
        complete(store, this.programId, advanceHook);
        break;
      case 'advancing':
        advance(store, this.programId, advanceHook);
        break;
      case 'completing-and-advancing':
        store.kettle.pause();
        completeAndAdvance(store, this.programId, advanceHook);
        break;
      case 'submitting-and-advancing':
        store.kettle.pause();
        submitAndAdvance(store, this.programId, state.submissionParams, advanceHook);
        break;
      case 'advancing-to':
        advancingTo(store, this.programId, advanceHook);
        break;
      case 'loaded':
        store.refreshKettle();
        store.ready(state.segmentResource);
        break;
      case 'waiting': {
        programsStore.resource
          .map(({ payload }) => payload.programs)
          .map<Task<SubmissionError, ProgramResource[]>>(Task.succeed)
          .getOrElse(always(Task.succeed([])))
          .andThen(findPayloadT(this.programId))
          .map((programResource) => programResource.links)
          .andThen(findLinkT('self'))
          .andThen(getProgram)
          .map(({ payload }) => payload)
          .map((program) =>
            whenActive(program)
              .map((p) => p.modules)
              .getOrElseValue([]),
          )
          .andThen(findItemT(this.moduleId))
          .map((m) => m.segments)
          .andThen(findItemT(this.segmentId))
          .map((s) => s.links)
          .andThen(findLinkT('self'))
          .fork(handleSubmissionError(store, this.programId, advanceHook), store.loading);
        break;
      }
      case 'reporting-results':
        store.results.do((results) => {
          Task.succeed<SegmentReactionErrors, ReadonlyArray<Link>>(store.links)
            .andThen(findLinkT('results-create'))
            .andThen(postToApi({ results: results.value }))
            .fork(reportingResultsFailure(store, store.enclosureOrigin), () =>
              reportingResultsSuccess(store, store.enclosureOrigin),
            );
        });
        break;
      case 'auto-saving-form-fields':
        const fields = store.embeddedFormFieldOutputs.map((embeddedFormFieldOutput) => ({
          id: embeddedFormFieldOutput.id,
          value: embeddedFormFieldOutput.value,
        }));

        Task.succeed<SegmentReactionErrors, ReadonlyArray<Link>>(store.links)
          .andThen(findLinkT('results-create'))
          .andThen(postToApi({ results: fields }))
          .fork(
            (err) => warnAndNotify('SegmentReactions auto-save error', err.kind, err),
            () => store.ready(state.segmentResource),
          );
        break;
      case 'ready':
        const { payload } = state.segmentResource;
        dataLayerUpdate({
          studentId: payload.studentId,
          studentStatus: payload.registrationStatus,
          registrationType: payload.registrationType,
          programId: payload.id,
          programName: payload.title,
          moduleId: payload.moduleId,
          segmentId: payload.id,
          segmentName: payload.title,
          learningCollectionId: payload.learningCollectionId,
          learningCollectionName: payload.learningCollectionName,
          productLicenseId: payload.productLicenseId,
          productLicenseType: payload.productLicenseType,
          programFamilyId: payload.programFamilyId,
          programFamilyName: payload.programFamilyName,
          schoolPartnerId: payload.schoolPartnerId,
          schoolPartnerName: payload.schoolPartnerName,
          enrollmentOpenDate: payload.enrollmentOpenDate,
          percentComplete: payload.percentComplete,
          duration: payload.duration,
          startsOn: payload.startsOn,
          endsOn: payload.endsOn,
          programTitle: payload.programTitle,
          programCreatedOn: payload.programCreatedOn,
          event: 'program-data',
        });
        break;
      case 'processing-request':
      case 'error':
      case 'schedule-session':
        break;
      default:
        assertNever(state);
    }
  };
}

export default Reactions;
