import { observer } from 'mobx-react';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import { warn } from '@execonline-inc/logging';
import { useFetch, useFindLinkByR } from '../../../../Fetch';
import { Link } from '../../../../Resource/Types';
import Loading from '../../ExperienceDetailView/Common/Loading';
import EnrollmentStore from '../Enrollment/Store';
import { promotablesResourceDecoder } from './Decoders';
import PromotablesCarouselView from './PromotablesCarouselView';

interface Props {
  links: ReadonlyArray<Link>;
  enrollmentStore: EnrollmentStore;
  invitationUuid: string;
}

function PromotablesView({ enrollmentStore, links, invitationUuid }: Props) {
  const link = useFindLinkByR({ rel: 'promotables', method: 'get' }, links);
  const state = useFetch(link, promotablesResourceDecoder);

  switch (state.kind) {
    case 'loading':
      return <Loading className="bg-transparent" />;
    case 'errored':
      warn(state.error);
      return emptyFragment();
    case 'ready':
      return (
        <div className="bg-white shadow-section">
          <div
            data-testid="promotables-section"
            className="mx-auto flex flex-col bg-inherit px-0 py-10 pb-6 pt-0 xl:container sm:py-8"
          >
            <div data-testid="promotables-section-body" className="px-6 md:px-16">
              <PromotablesCarouselView
                promotables={state.data.payload}
                enrollmentStore={enrollmentStore}
                invitationUuid={invitationUuid}
              />
            </div>
          </div>
        </div>
      );
  }
}

export default observer(PromotablesView);
