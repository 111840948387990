import { Button, TextField } from '@execonline-inc/execonline-ui';
import { just, nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { T } from '../../Translations';
import { LoginStore } from '../Store';
import LoginFormWrapper from './LoginFormWrapper';

interface Props {
  store: LoginStore;
}

const EmailForm: React.FC<Props> = ({ store }) => {
  return (
    <LoginFormWrapper store={store}>
      <form
        data-testid="login-email-form"
        name="email_login_form"
        className="mx-auto w-80"
        onSubmit={submit(store)}
      >
        <TextField
          data-testid="login-email-form_email-text-field"
          autoFocus
          type="email"
          name="email"
          id="login_email"
          autoComplete="username"
          disabled={store.formShouldBeDisabled}
          label={<T kind="Email or Username" />}
          labelProps={{
            className: 'uppercase tracking-wider',
          }}
          helperText="email@domain.com; username@id"
          helperTextProps={{ className: 'font-medium' }}
          onChange={setUsername(store)}
        />
        <Button
          data-testid="login-email-form_next-button"
          className="mt-7 w-full sm:w-auto"
          color="green"
          type="submit"
          radius="sm"
          disabled={store.formShouldBeDisabled}
        >
          <T kind="Next" />
        </Button>
      </form>
    </LoginFormWrapper>
  );
};

export default observer(EmailForm);

const setUsername =
  (store: LoginStore) =>
  (event: React.FormEvent<HTMLInputElement>): void => {
    store.awaitingUsername({ username: just(event.currentTarget.value), error: nothing() });
  };

const submit =
  (store: LoginStore) =>
  (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    store.identifyingAssertionBackend();
  };
