import { readVarM } from '@execonline-inc/environment';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import { toUrl } from '@execonline-inc/url';
import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import Login from '.';
import { resetPasswordRoute } from '../../ClientRoutes';
import { Typography } from '../../components/UI';
import { T } from '../../Translations';
import ExternalLink from '../AEP/ExternalLink';
import MailToLink from '../AEP/MailToLink';

interface Props {}

const LockoutNotice: React.FC<Props> = () => (
  <Login>
    <Typography className="mb-10">
      <T
        kind="If this email address is in our database you ..."
        emailAddress={
          <MailToLink email={supportEmail}>
            <Typography
              className="hover:underline"
              component="span"
              color="inherit"
              fontWeight="medium"
            >
              {supportEmail}
            </Typography>
          </MailToLink>
        }
      />
    </Typography>
    <div className="my-5">
      {just({})
        .assign('base', readVarM('REACT_APP_BASE_NAME'))
        .assign('origin', readVarM('REACT_APP_API_ROOT'))
        .andThen(({ base, origin }) => toUrl(base + resetPasswordRoute, origin))
        .map((url) => (
          <ExternalLink href={url.href}>
            <Typography className="underline" variant="body2" component="span">
              <T kind="I forgot my password" />
            </Typography>
          </ExternalLink>
        ))
        .getOrElse(emptyFragment)}
    </div>
  </Login>
);

export default observer(LockoutNotice);

const supportEmail = 'support@execonline.com';
