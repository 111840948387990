import Decoder, { array, field, number, oneOf, succeed } from 'jsonous';
import { resourceDecoder } from '../../../../Resource/Decoders';
import { stringLiteral } from '@execonline-inc/decoders';
import { experienceResourceDecoder } from '../ExperienceSelection/Experiences/Decoders';
import { identity } from '@kofno/piper';
import {
  DevelopmentPathPromotable,
  GroupCoachingPromotable,
  LeadershipCoachingPromotable,
  ProgramPromotable,
  Promotable,
  PromotableBase,
  PromotableExperienceBase,
  PromotableResource,
  PromotablesResource,
  Style,
} from './Types';

const styleDecoder: Decoder<Style> = oneOf<Style>([stringLiteral<Style>('carousel')]);

const promotableBaseDecoder: Decoder<PromotableBase> = succeed({})
  .assign('style', field('style', styleDecoder))
  .assign('id', field('id', number));

const promotableExperienceBaseDecoder: Decoder<PromotableExperienceBase> =
  promotableBaseDecoder.assign(
    'experienceResource',
    field('experience_resource', experienceResourceDecoder),
  );

const developmentPathDecoder: Decoder<DevelopmentPathPromotable> =
  promotableExperienceBaseDecoder.assign('kind', field('kind', stringLiteral('development-path')));

const leadershipCoachingDecoder: Decoder<LeadershipCoachingPromotable> =
  promotableExperienceBaseDecoder.assign(
    'kind',
    field('kind', stringLiteral('leadership-coaching')),
  );

const programDecoder: Decoder<ProgramPromotable> = promotableExperienceBaseDecoder.assign(
  'kind',
  field('kind', stringLiteral('program')),
);

const groupCoachingDecoder: Decoder<GroupCoachingPromotable> =
  promotableExperienceBaseDecoder.assign('kind', field('kind', stringLiteral('group-coaching')));

const promotableDecoder: Decoder<Promotable> = oneOf<Promotable>([
  developmentPathDecoder.map<Promotable>(identity),
  programDecoder.map<Promotable>(identity),
  groupCoachingDecoder.map<Promotable>(identity),
  leadershipCoachingDecoder.map<Promotable>(identity),
]);

const promotableResourceDecoder: Decoder<PromotableResource> = resourceDecoder(promotableDecoder);

export const promotablesResourceDecoder: Decoder<PromotablesResource> = resourceDecoder(
  array(promotableResourceDecoder),
);
