import { observer } from 'mobx-react';
import React from 'react';
import { ExperienceResources } from '../../../../../../Common/Experience/Types';
import { TileViewProgramExperience } from '../../../../../../Common/ProgramExperience';
import EnrollmentStore from '../../../../../Enrollment/Store';
import CompetencyFilterStore from '../../../../Filters/CompetencyFilter/Store';
import DurationFilterStore from '../../../../Filters/DurationFilter/Store';
import FilterStore from '../../../../Filters/FilterStore';
import LanguageFilterStore from '../../../../Filters/LanguageFilter/Store';
import SchoolPartnerFilterStore from '../../../../Filters/SchoolPartnerFilter/Store';
import { useExperiencesContext } from '../../../ExperiencesContext';

interface Props {
  invitationUuid: string;
  experiences: ExperienceResources;
  enrollmentStore: EnrollmentStore;
  filterStore: FilterStore;
  languageFilterStore: LanguageFilterStore;
  durationFilterStore: DurationFilterStore;
  schoolPartnerFilterStore: SchoolPartnerFilterStore;
  competencyFilterStore: CompetencyFilterStore;
}

const ShowExperiences: React.FC<Props> = ({
  invitationUuid,
  experiences,
  enrollmentStore,
  filterStore,
  languageFilterStore,
  durationFilterStore,
  schoolPartnerFilterStore,
  competencyFilterStore,
}) => {
  const experiencesStore = useExperiencesContext();

  return (
    <div className="mt-14 grid gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
      {experiences
        .filter((experience) =>
          filterStore.showFilteredExperience(
            experience,
            languageFilterStore,
            durationFilterStore,
            schoolPartnerFilterStore,
            competencyFilterStore,
          ),
        )
        .map((experience) => {
          return (
            <div
              key={experience.payload.experienceId}
              className="box-border flex w-full sm:max-w-full xl:min-w-[17rem] xl:max-w-[26.625rem]"
            >
              <TileViewProgramExperience
                invitationUuid={invitationUuid}
                experienceResource={experience}
                experiencesStore={experiencesStore}
                enrollmentStore={enrollmentStore}
              />
            </div>
          );
        })}
    </div>
  );
};

export default observer(ShowExperiences);
