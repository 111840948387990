import { emptyFragment, Typography } from '@execonline-inc/execonline-ui';
import { AlreadyTranslated } from '@execonline-inc/translations';
import { observer } from 'mobx-react';
import { HtmlView } from '../../../../Common/Experience';
import ExperiencesStore from '../../Experiences/ExperiencesStore';
import { State } from '../../Experiences/ExperiencesStore/Types';
import { Maybe, nothing } from 'maybeasy';
import { Link } from '../../../../../../Resource/Types';
import { findLinkBy } from '../../../../../../Fetch';
import HtmlImageView from '../../../../Common/Experience/ExperienceHtmlView/HtmlImageView';

interface Props {
  experiencesStore: ExperiencesStore;
}

const learningCollectionLogoLink = (state: State): Maybe<Link> => {
  switch (state.kind) {
    case 'error':
    case 'waiting':
    case 'loading':
      return nothing();
    case 'searching':
    case 'experiences':
    case 'product-collections':
    case 'product-collections-and-experiences':
      return findLinkBy({ rel: 'learning-collection-logo' }, state.resource.links);
  }
};

function WelcomeMessage({ experiencesStore }: Props) {
  return experiencesStore.useCase
    .map((useCase) => (
      <div className="sm:px-0">
        <div className="container mx-auto box-border w-full items-center justify-between px-6 pb-8 pt-11 md:px-16">
          <Typography className="text-black" variant="h4" as="h1">
            <AlreadyTranslated content={useCase.name} />
          </Typography>
          <div className="flex flex-col items-center justify-between md:flex-row md:items-start">
            <HtmlView description={useCase.description} className="md:mr-20" />
            {learningCollectionLogoLink(experiencesStore.state)
              .map((link) => <HtmlImageView src={link.href} />)
              .getOrElse(emptyFragment)}
          </div>
        </div>
      </div>
    ))
    .getOrElse(emptyFragment);
}

export default observer(WelcomeMessage);
