import { emptyFragment } from '@execonline-inc/execonline-ui';
import { Maybe, nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import { fromArrayMaybe } from 'nonempty-list';
import { useEffect, useState } from 'react';
import { findLink } from '../../LinkyLinky';
import { ProgramSegment } from '../../ProgramStore/Types';
import { AutoLaunchableStore } from '../AutoLaunchable/AutoLaunchableStore';
import AutoLaunchableModalStore from './AutoLaunchableModalStore';
import ModalSegment from './ModalSegment';
import ModalWrapping from './ModalWrapping';

interface Props {
  autoLaunchableModalStore: AutoLaunchableModalStore;
  autoLaunchableStore: AutoLaunchableStore;
}

function AutoLaunchableModal({ autoLaunchableModalStore, autoLaunchableStore }: Props) {
  const [title, setTitle] = useState('');
  const [currentSegment, setCurrentSegment] = useState<Maybe<ProgramSegment>>(nothing);

  const getCurrentSegment = (segments: ProgramSegment[]) =>
    fromArrayMaybe(segments.filter((s) => s.isCurrentSegment)).map(({ first }) => first);

  useEffect(() => {
    switch (autoLaunchableStore.state.kind) {
      case 'ready':
        const { autoLaunchableResource } = autoLaunchableStore.state;
        setTitle(autoLaunchableResource.payload.title.text);
        setCurrentSegment(getCurrentSegment(autoLaunchableResource.payload.segments));
        break;
      case 'nothing-to-launch':
        setCurrentSegment(nothing);
        break;
      case 'loading':
      case 'error':
        break;
    }
  }, [autoLaunchableStore.state]);

  return currentSegment
    .map((currentSegment) => (
      <ModalWrapping
        title={title}
        closeM={findLink('participant-postponables', currentSegment.links).map(
          (link) => () => autoLaunchableModalStore.postpone(link),
        )}
      >
        <ModalSegment currentSegment={currentSegment} autoLaunchableStore={autoLaunchableStore} />
      </ModalWrapping>
    ))
    .getOrElse(emptyFragment);
}

export default observer(AutoLaunchableModal);
