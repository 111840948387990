import { emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import ErrorMessage from '../../../../ErrorMessage';
import EnrollmentStore from '../../Enrollment/Store';
import Promotables from '../../PromotablesSection';
import CompetencyFilterStore from '../Filters/CompetencyFilter/Store';
import DurationFilterStore from '../Filters/DurationFilter/Store';
import FilterStore from '../Filters/FilterStore';
import LanguageFilterStore from '../Filters/LanguageFilter/Store';
import SchoolPartnerFilterStore from '../Filters/SchoolPartnerFilter/Store';
import ExperiencesStore from './ExperiencesStore';
import Notification from './Notification';
import ProductCollectionsAndExperiences from './ProductCollectionsAndExperiences';
import SkeletonSearchExperiences from './ProductCollectionsAndExperiences/SkeletonProductCollections/SkeletonSearchExperiences';

interface Props {
  invitationUuid: string;
  experiencesStore: ExperiencesStore;
  enrollmentStore: EnrollmentStore;
  filterStore: FilterStore;
  languageFilterStore: LanguageFilterStore;
  durationFilterStore: DurationFilterStore;
  schoolPartnerFilterStore: SchoolPartnerFilterStore;
  competencyFilterStore: CompetencyFilterStore;
}

function Experiences({
  competencyFilterStore,
  durationFilterStore,
  enrollmentStore,
  filterStore,
  invitationUuid,
  languageFilterStore,
  schoolPartnerFilterStore,
  experiencesStore,
}: Props) {
  switch (experiencesStore.state.kind) {
    case 'waiting':
    case 'loading':
      return emptyFragment();
    case 'searching':
      return <SkeletonSearchExperiences />;
    case 'experiences':
    case 'product-collections':
    case 'product-collections-and-experiences':
      return (
        <>
          <Notification notification={enrollmentStore.notification} />
          <div className="flex flex-1 flex-col gap-y-8">
            <Promotables
              experiencesStore={experiencesStore}
              enrollmentStore={enrollmentStore}
              invitationUuid={invitationUuid}
            />
            <ProductCollectionsAndExperiences
              experiencesStore={experiencesStore}
              enrollmentStore={enrollmentStore}
              invitationUuid={invitationUuid}
              filterStore={filterStore}
              languageFilterStore={languageFilterStore}
              durationFilterStore={durationFilterStore}
              schoolPartnerFilterStore={schoolPartnerFilterStore}
              competencyFilterStore={competencyFilterStore}
            />
          </div>
        </>
      );
    case 'error':
      return <ErrorMessage message="Unable to load your experiences" />;
  }
}

export default observer(Experiences);
