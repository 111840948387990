import { useMediaQuery } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { ReactNode } from 'react';
import { PreviewModeContext } from '../../../DiscoveryPortal/PreviewMode/PreviewModeContext';
import { CarouselStore } from '../../Experience/Carousel';
import { ProgramExperienceCarouselProps } from '../Types';
import ExperienceList from './ExperienceList';
import MobileExperienceList from './MobileExperienceList';

interface Props<T> {
  carouselStore: CarouselStore<T>;
  children: (props: ProgramExperienceCarouselProps<T>) => ReactNode;
}

function ProgramExperienceItems<T>({ carouselStore, children }: Props<T>) {
  const breakPoint = useMediaQuery();

  switch (breakPoint) {
    case 'xl':
      return (
        <ExperienceList
          page={carouselStore.currentPage}
          perPageCount={carouselStore.perPageCount}
          children={children}
        />
      );
    case 'lg':
    case 'md':
    case 'sm':
    case 'xs':
      return (
        <PreviewModeContext.Consumer>
          {(preview) => {
            switch (preview) {
              case 'default':
                return <MobileExperienceList items={carouselStore.contents} children={children} />;
              case 'preview':
                return (
                  <ExperienceList
                    page={carouselStore.currentPage}
                    perPageCount={carouselStore.perPageCount}
                    children={children}
                  />
                );
            }
          }}
        </PreviewModeContext.Consumer>
      );
  }
}

export default observer(ProgramExperienceItems);
