import { observer } from 'mobx-react';
import * as React from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { unsubscribeProgressEmailRoute } from '../../ClientRoutes';
import WithCurrentUser from '../../CurrentUser/Context/WithCurrentUser';
import { programShowEngagement } from '../../EngagementStore/Types';
import EventsStore from '../../EventsStore';
import history from '../../History';
import Explore from '../../Native/AEP/Explore';
import { AutoLaunchableContext } from '../AutoLaunchable/AutoLaunchableContext';
import ConferenceRouter from '../ConferenceRouter';
import CourseDocument from '../CourseDocument';
import EngageableView from '../Engageable/EngageableView';
import EventDashboard from '../EventDashboard';
import NotFound from '../NotFound';
import Notes from '../Notes';
import ProfileEdit from '../Profile/Edit';
import Program from '../Program';
import ProgramSummary from '../ProgramSummary';
import Resource from '../Resource';
import Segment from '../Segment';
import SegmentResource from '../SegmentResource';
import SegmentStub from '../SegmentStub';
import Sitemap from '../Sitemap';
import Team from '../Team';
import TeamMembersList from '../TeamMembersList';
import UnsubscribeProgressEmail from '../UnsubscribeProgressEmail';
import ViewableAsset from '../ViewableAsset';
import ViewableAssignment from '../ViewableAssignment';
import ViewableAttachment from '../ViewableAttachment';
import MainPanel from './MainPanel';
import SegmentPreview from './SegmentPreview';

interface Props {
  eventsStore: EventsStore;
}

const PlatformRouter: React.FC<Props> = ({ eventsStore }) => {
  return (
    <Router history={history}>
      <Switch>
        <Route path="/sitemap" component={Sitemap} />
        <Route
          exact
          path={unsubscribeProgressEmailRoute}
          render={(props) => <UnsubscribeProgressEmail id={props.match.params.id} />}
        />
        <Route path="/resources" render={() => <MainPanel eventsStore={eventsStore} />} />
        <Route
          path="/programs/:programId/resources/:resourceId"
          component={Resource}
          exact={true}
        />
        <Route path="/teams/:teamId/conference" component={ConferenceRouter} exact={true} />
        <Route path="/events/:eventId/teams/:teamId" component={ConferenceRouter} exact={true} />
        <Route path="/events/:eventId/conference" component={ConferenceRouter} exact={true} />
        <Route
          path="/events/:eventId/dashboard"
          exact
          render={({ match }) => (
            <WithCurrentUser
              children={(currentUserResource) => (
                <EventDashboard match={match} currentUserResource={currentUserResource} />
              )}
            />
          )}
        />
        <Route path="/notes" component={Notes} />
        <Route
          path="/programs/:programId"
          render={(props) => (
            <AutoLaunchableContext.Provider value="program_dashboard">
              <EngageableView engagement={programShowEngagement(props.match.params.programId)}>
                <ProgramSummary {...props} />
              </EngageableView>
            </AutoLaunchableContext.Provider>
          )}
          exact={true}
        />
        <Route
          path="/programs/:programId/modules/:moduleId/segments/:segmentId"
          component={Segment}
          exact={true}
        />
        <Route
          path="/programs/:programId/modules/:moduleId/segmentStubs/:segmentId"
          component={SegmentStub}
          exact={true}
        />
        <Route
          path="/programs/:programId/modules/:moduleId/segments/:segmentId/resources/:resourceId"
          component={SegmentResource}
          exact={true}
        />
        <Route
          exact
          path="/programs/:programId/segment_preview/:segmentId"
          component={SegmentPreview}
        />
        <Route path="/viewable_assets/:assetId" component={ViewableAsset} />
        <Route path="/viewable_attachments/:attachmentId" component={ViewableAttachment} />
        <Route path="/course_documents/:documentId" component={CourseDocument} />
        <Route
          path="/assignments/:assignmentId/viewable_assignments/:attachmentId"
          component={ViewableAssignment}
        />
        <Route
          path="/profile/edit"
          exact={true}
          render={() => (
            <WithCurrentUser
              children={(currentUserResource) => (
                <ProfileEdit currentUserResource={currentUserResource} />
              )}
            />
          )}
        />
        <Route path="/team" component={Team} exact={true} />
        <Route path="/teams/:teamsId" component={Team} exact={true} />
        <Route path="/program_chat" component={Program} exact={true} />
        <Route path="/program_chats/:programsId" component={Program} exact={true} />
        <Route
          path="/teams/:teamId/team-members"
          render={({ match }) => <TeamMembersList match={match} />}
        />
        <Route
          path="/(calendar|communities|index.html)/"
          render={() => <MainPanel eventsStore={eventsStore} />}
          exact={true}
        />
        <Route exact path="/explore" render={() => <Explore />} />
        <Route path="/" exact={true} render={() => <MainPanel eventsStore={eventsStore} />} />
        <Route path="/404" component={NotFound} />
        <Redirect from="*" to="/404" />
      </Switch>
    </Router>
  );
};

export default observer(PlatformRouter);
