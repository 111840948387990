import { Button, TextField, Typography } from '@execonline-inc/execonline-ui';
import { fromEmpty, nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { resetPasswordRoute } from '../../ClientRoutes';
import { T } from '../../Translations';
import { LoginStore } from '../Store';
import LoginFormWrapper from './LoginFormWrapper';

interface Props {
  store: LoginStore;
  username: string;
}

const PasswordForm: React.FC<Props> = ({ store, username }) => (
  <LoginFormWrapper store={store}>
    <form
      data-testid="login-password-form"
      name="password_login_form"
      className="mx-auto w-80"
      onSubmit={submit(store)}
      onReset={cancel(store)}
    >
      <Typography
        data-testid="login-password-form_selected-email-label"
        className="my-8 text-left text-neutral-900"
      >
        {username}
      </Typography>
      <input hidden={true} value={username} readOnly={true} autoComplete="username" />
      <TextField
        data-testid="login-password-form_password-text-field"
        autoFocus
        label={<T kind="Password" />}
        labelProps={{ className: 'uppercase tracking-wider' }}
        helperText={
          <Link
            className="text-neutral-900 opacity-80 hover:text-neutral-900 hover:opacity-100"
            to={resetPasswordRoute}
            data-test-forgot-password-link={true}
          >
            <T kind="I forgot my password" />
          </Link>
        }
        type="password"
        onChange={setPassword(store)}
        name="password"
        id="login_password"
        className="text-neutral-900"
        autoComplete="current-password"
        disabled={store.formShouldBeDisabled}
      />
      <div className="mt-7 flex flex-col justify-center gap-3 sm:flex-row">
        <Button
          data-testid="login-password-form_go-back-button"
          color="gray"
          type="reset"
          variant="outlined"
          className="w-full text-gray-600-old sm:w-auto"
          radius="sm"
          disabled={store.formShouldBeDisabled}
        >
          <T kind="Go Back" />
        </Button>
        <Button
          data-testid="login-password-form_login-to-account-button"
          className="w-full sm:w-auto"
          color="green"
          type="submit"
          name="Login"
          radius="sm"
          disabled={store.formShouldBeDisabled}
        >
          <T kind="Login to Your Account" />
        </Button>
      </div>
    </form>
  </LoginFormWrapper>
);

export default observer(PasswordForm);

const setPassword =
  (store: LoginStore) =>
  (event: React.FormEvent<HTMLInputElement>): void =>
    store.awaitingPassword({ password: fromEmpty(event.currentTarget.value), error: nothing() });

const cancel =
  (store: LoginStore) =>
  (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    store.awaitingUsername({ username: nothing(), error: nothing() });
  };

const submit =
  (store: LoginStore) =>
  (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    store.submittingPassword();
  };
