import { useMemo } from 'react';
import { observer } from 'mobx-react';
import { CarouselStore } from '../../Common/Experience/Carousel';
import { ProgramExperienceCarousel } from '../../Common/ProgramExperienceCarousel';
import EnrollmentStore from '../Enrollment/Store';
import { useExperiencesContext } from '../ExperienceSelection/Experiences/ExperiencesContext';
import PromotableCarouselTile from './PromotableCarouselTile';
import { PromotableResource } from './Types';

interface Props {
  invitationUuid: string;
  promotables: ReadonlyArray<PromotableResource>;
  enrollmentStore: EnrollmentStore;
}

function PromotablesCarouselView({ promotables, enrollmentStore, invitationUuid }: Props) {
  const experiencesStore = useExperiencesContext();
  const carouselStore = useMemo(() => new CarouselStore(promotables, 1), [promotables]);

  return (
    <ProgramExperienceCarousel carouselStore={carouselStore}>
      {({ items }) =>
        items.map(({ payload }) => (
          <PromotableCarouselTile
            key={payload.id}
            invitationUuid={invitationUuid}
            experienceResource={payload.experienceResource}
            experiencesStore={experiencesStore}
            enrollmentStore={enrollmentStore}
          />
        ))
      }
    </ProgramExperienceCarousel>
  );
}

export default observer(PromotablesCarouselView);
