import { observer } from 'mobx-react';
import { Typography } from '@execonline-inc/execonline-ui';
import { T } from '../../../../../Translations';
import { ExperienceContextProvider } from '../../../Common/Experience/ExperienceContext';
import { ExperienceResource } from '../../../Common/Experience/Types';
import { TileViewProgramExperiencePreview } from '../../../Common/ProgramExperience';
import { ExperienceEnrollmentContextProvider } from '../../../Common/ProgramExperienceEnrollment';
import EnrollmentStore from '../../Enrollment/Store';
import ExperiencesStore from '../../ExperienceSelection/Experiences/ExperiencesStore';
import PromotableExperienceTileFooter from './PromotableExperienceTileFooter';

interface Props {
  invitationUuid: string;
  experiencesStore: ExperiencesStore;
  enrollmentStore: EnrollmentStore;
  experienceResource: ExperienceResource;
}

function PromotableExperienceTileView(props: Props) {
  const { invitationUuid, experienceResource, enrollmentStore } = props;

  return (
    <ExperienceEnrollmentContextProvider value={props}>
      <ExperienceContextProvider value={{ invitationUuid, experienceResource }}>
        <section>
          <Typography variant="h5">
            <T kind="Grow with leadership coaching" />
          </Typography>
          <div className="box-border flex w-full grid-cols-2 gap-x-16 pr-8">
            <div className="flex w-full flex-col gap-y-10">
              <Typography className="mt-5" variant="bodyLarge">
                <T kind="Your coach is ready to transform your leadership!" />
              </Typography>
              <Typography variant="bodyLarge">
                <T kind="Leadership coaching is available to you at any time during your license..." />
              </Typography>
            </div>
            <TileViewProgramExperiencePreview
              className="w-full"
              invitationUuid={invitationUuid}
              experienceResource={experienceResource}
            />
          </div>
          <PromotableExperienceTileFooter
            invitationUuid={invitationUuid}
            enrollmentStore={enrollmentStore}
            experienceResource={experienceResource}
          />
        </section>
      </ExperienceContextProvider>
    </ExperienceEnrollmentContextProvider>
  );
}

export default observer(PromotableExperienceTileView);
