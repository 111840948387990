import * as React from 'react';
import { observer } from 'mobx-react';
import { when } from '@execonline-inc/maybe-adapter';
import AttachmentUploadStore from '../../../../../AttachmentUploadStore';
import SegmentStore from '../../../../../SegmentStore';
import DisabledButton from './DisabledButton';
import FormFieldButton from './FormFieldButton';
import ScheduleSessionButton from './ScheduleSessionButton';
import { Maybe } from 'maybeasy';
import { TPlainTextKey } from '../../../../../Translations';
import { SegmentResource } from '../../../../../SegmentStore/Types';
import { emptyFragment } from '@execonline-inc/execonline-ui';

interface Props {
  segmentStore: SegmentStore;
  btnStyle: string;
  children: { btnIcon: Maybe<JSX.Element> };
  text: TPlainTextKey;
}

const showCallToActionButton = (segmentStore: SegmentStore): boolean => {
  return segmentStore.segmentResource.cata({
    Just: (segmentResource: SegmentResource) => {
      switch (segmentResource.payload.type) {
        case 'overview':
          return segmentResource.payload.showCompletionCta;
        case 'presentation':
        case 'assignment-due':
        case 'team-discussion':
        case 'external-program':
        case 'lecture':
        case 'survey':
          return false;
      }
    },
    Nothing: () => {
      return false;
    },
  });
};

class SubmitAndAdvanceButton extends React.Component<Props> {
  attachmentUploadStore: AttachmentUploadStore = new AttachmentUploadStore();

  submit = () => {
    this.props.segmentStore.submitAndAdvance({
      type: 'overview',
      embeddedFormFieldOutputs: this.props.segmentStore.embeddedFormFieldOutputs,
      attachmentUploadStore: this.attachmentUploadStore,
    });
  };

  render() {
    const { segmentStore, btnStyle, children, text } = this.props;
    switch (this.props.segmentStore.state.kind) {
      case 'ready':
      case 'processing-request':
      case 'auto-saving-form-fields':
        return when(showCallToActionButton(this.props.segmentStore), true)
          .map(() => (
            <FormFieldButton
              submit={this.submit}
              segmentStore={segmentStore}
              btnStyle={btnStyle}
              btnIcon={children.btnIcon}
              text={text}
            />
          ))
          .getOrElse(emptyFragment);

      case 'schedule-session':
        return when(this.props.segmentStore.isFreebusyCoaching, {})
          .map(() => (
            <ScheduleSessionButton
              submit={this.submit}
              segmentStore={segmentStore}
              btnStyle={btnStyle}
              btnIcon={children.btnIcon}
              text={text}
            />
          ))
          .getOrElse(() => (
            <FormFieldButton
              submit={this.submit}
              segmentStore={segmentStore}
              btnStyle={btnStyle}
              btnIcon={children.btnIcon}
              text={text}
            />
          ));
      case 'advancing':
      case 'advancing-to':
      case 'completing':
      case 'completing-and-advancing':
      case 'reporting-results':
      case 'submitting-and-advancing':
      case 'loaded':
      case 'loading':
      case 'error':
      case 'waiting':
        return <DisabledButton text={text} btnStyle={btnStyle} btnIcon={children.btnIcon} />;
    }
  }
}

export default observer(SubmitAndAdvanceButton);
