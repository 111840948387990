import React from 'react';
import { observer } from 'mobx-react';
import { equals } from '@execonline-inc/collections';
import { cn, emptyFragment, useDeviceDetect } from '@execonline-inc/execonline-ui';
import { when } from '@execonline-inc/maybe-adapter';
import { fromEmpty, isNothing } from 'maybeasy';
import { State } from '../../Experiences/ExperiencesStore/Types';
import SearchBoxField from './SearchBoxField';
import SearchBoxButton from './SearchBoxButton';
import { useSearchBox } from './hooks';

interface Props {
  className: string;
  state: State;
  onSubmit: (value: string) => void;
}

function SearchBox({ className, state, onSubmit }: Props) {
  const { value, onChange, validateValue } = useSearchBox(state);
  const deviceType = useDeviceDetect();

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    validateValue().do(() => onSubmit(value));
  };

  return (
    <form className={cn('flex gap-2', className)} onSubmit={handleSubmit}>
      <SearchBoxField search={value} onChange={onChange} />
      {when(equals(deviceType, 'desktop'), true)
        .map(() => <SearchBoxButton disabled={isNothing(fromEmpty(value))} />)
        .getOrElse(emptyFragment)}
    </form>
  );
}

export default observer(SearchBox);
