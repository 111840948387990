import { emptyFragment } from '@execonline-inc/execonline-ui';
import { assertNever } from '@kofno/piper';
import { observer } from 'mobx-react';
import React from 'react';
import { ExperienceResource, ExperienceResources } from '../../../../Common/Experience/Types';
import EnrollmentStore from '../../../Enrollment/Store';
import CompetencyFilterStore from '../../Filters/CompetencyFilter/Store';
import DurationFilterStore from '../../Filters/DurationFilter/Store';
import FilterStore from '../../Filters/FilterStore';
import LanguageFilterStore from '../../Filters/LanguageFilter/Store';
import SchoolPartnerFilterStore from '../../Filters/SchoolPartnerFilter/Store';
import {
  whenAnyMatchingAvailableLanguages,
  whenAnyMatchingDuration,
  whenAnyMatchingSchoolPartner,
} from '../../Filters/Types';
import { ExperiencesContext } from '../ExperiencesContext';
import ExperiencesStore from '../ExperiencesStore';
import BothProductCollectionsAndExperiences from './BothProductCollectionsAndExperiences';
import Experiences from './Experiences';
import ProductCollections from './ProductCollections';

interface Props {
  invitationUuid: string;
  experiencesStore: ExperiencesStore;
  enrollmentStore: EnrollmentStore;
  filterStore: FilterStore;
  languageFilterStore: LanguageFilterStore;
  durationFilterStore: DurationFilterStore;
  schoolPartnerFilterStore: SchoolPartnerFilterStore;
  competencyFilterStore: CompetencyFilterStore;
}

class ProductCollectionsAndExperiences extends React.Component<Props> {
  componentDidMount() {
    this.props.enrollmentStore.settingUp(this.props.experiencesStore.useCase);
  }

  render() {
    const {
      experiencesStore,
      invitationUuid,
      enrollmentStore,
      filterStore,
      languageFilterStore,
      durationFilterStore,
      schoolPartnerFilterStore,
      competencyFilterStore,
    } = this.props;

    const anyExperienceThatMatchesFilters = (experience: ExperienceResource): boolean => {
      return (
        whenAnyMatchingAvailableLanguages(experience, languageFilterStore) &&
        whenAnyMatchingDuration(experience, durationFilterStore) &&
        whenAnyMatchingSchoolPartner(experience, schoolPartnerFilterStore)
      );
    };

    const filteredExperiences = (experiences: ExperienceResources): ExperienceResources =>
      experiences.filter(anyExperienceThatMatchesFilters);

    switch (experiencesStore.state.kind) {
      case 'experiences':
        return (
          <ExperiencesContext.Provider value={experiencesStore}>
            <Experiences
              invitationUuid={invitationUuid}
              enrollmentStore={enrollmentStore}
              filterStore={filterStore}
              searchValue={experiencesStore.state.search}
              languageFilterStore={languageFilterStore}
              durationFilterStore={durationFilterStore}
              schoolPartnerFilterStore={schoolPartnerFilterStore}
              competencyFilterStore={competencyFilterStore}
              experiences={filteredExperiences(experiencesStore.state.experiences)}
              displayType={experiencesStore.state.resource.payload.useCase.displayType}
            />
          </ExperiencesContext.Provider>
        );
      case 'product-collections':
        return (
          <ExperiencesContext.Provider value={experiencesStore}>
            <ProductCollections
              enrollmentStore={enrollmentStore}
              invitationUuid={invitationUuid}
              productCollections={experiencesStore.productCollections}
              filterStore={filterStore}
              searchValue={experiencesStore.state.search}
              languageFilterStore={languageFilterStore}
              durationFilterStore={durationFilterStore}
              schoolPartnerFilterStore={schoolPartnerFilterStore}
              competencyFilterStore={competencyFilterStore}
            />
          </ExperiencesContext.Provider>
        );
      case 'product-collections-and-experiences':
        return (
          <ExperiencesContext.Provider value={experiencesStore}>
            <BothProductCollectionsAndExperiences
              enrollmentStore={enrollmentStore}
              invitationUuid={invitationUuid}
              productCollections={experiencesStore.productCollections}
              filterStore={filterStore}
              languageFilterStore={languageFilterStore}
              searchValue={experiencesStore.state.search}
              durationFilterStore={durationFilterStore}
              schoolPartnerFilterStore={schoolPartnerFilterStore}
              competencyFilterStore={competencyFilterStore}
              experiences={filteredExperiences(experiencesStore.state.experiences)}
              displayType={experiencesStore.state.resource.payload.useCase.displayType}
            />
          </ExperiencesContext.Provider>
        );
      case 'error':
      case 'loading':
      case 'waiting':
      case 'searching':
        return emptyFragment();
      default:
        assertNever(experiencesStore.state);
    }
  }
}

export default observer(ProductCollectionsAndExperiences);
